!function(window) {

function bootstrap_navbar_functionality() {

	bindEvents();
	setActiveUrl();
	

	// -- BIND SET ACTIVE PATH -- //
	function setActiveUrl() {

		// navbar selector
		var $navbar = $("#main-menu-bootstrap");

		var currentUrl = window.location.pathname;
		// strip first and last slash
		if(currentUrl[0] === '/') {
			currentUrl = currentUrl.substring(1);
		}
		if(currentUrl[currentUrl.length-1] === '/') {
			currentUrl = currentUrl.substring(0, currentUrl.length-1);
		}

		// split by middle slashes
		var split = currentUrl.split('/');
		
		//set active-content to active anchors recursive
		setActiveUrlRecursive(split, $navbar);

		function setActiveUrlRecursive(aPaths, $selector) {

			// check if any paths
			if (!aPaths.length) {
				return;
			}
			// find child with selected url
			var $child = $($selector).find(`a[data-url-v-dir="${aPaths[0].toLowerCase()}"]`);
			if (!$child.length) {
				// end if no child
				return;
			}
			$child.addClass('active-content'); // add class to manipulate looks

			// remove first from array and check if we came to end
			aPaths.splice(0,1);
			if (!aPaths.length) {
				return;
			}

			//check if there is a submenu that has to be checked 
			// case when id is last 
			var $submenuOfChild = $child.siblings('.dropdown-menu'); 
			if (!$submenuOfChild.length) {
				return;
			}

			setActiveUrlRecursive(aPaths, $submenuOfChild);
		}
	}

	// -- BIND SHOW HIDE SUBMENUS -- //
	function bindEvents() {
		$('#main-menu-bootstrap li[class*="-has-children"]').on('mouseover', function(){
			var $li = $(this);
			if(!$li.hasClass('open open-fade')) {
				$(this).addClass('open open-fade');
			}
		});
		$('#main-menu-bootstrap li[class*="-has-children"]').on('mouseleave', function(ev){
			var $li = $(this);	
			if($li.hasClass('open open-fade')) {
				$(this).removeClass('open open-fade');
			}
		});
		
		$("#main-menu-bootstrap a").on("click", function(ev){
			// on bigscreens hide navbar when anchor clicked
			//settimeout because display block happens instantly and it causes bugs
			setTimeout(function(){
				$("#main-menu-bootstrap > li.bs-level1-has-children.open").removeClass('open');
			}, 50);
		});
	}

}
module.exports = bootstrap_navbar_functionality;
window.bootstrap_navbar_functionality = bootstrap_navbar_functionality;
}.call(window, window);